function Tableau() {
    return (
        <div>
            <h1>Tableau Section</h1>
            <p>Include Tableau dashboards and visuals here.</p>
        </div>
    );
}

export default Tableau;
