import React from 'react';
import resumeData from './resumeData'; // Import the resume data

function Home() {
    return (
        <div className="container mt-4">
            {/* Welcome Message Card */}
            <div className="card shadow-sm mb-4">
                <div className="card-body text-center">
                    <h2 className="card-title mb-3 text-primary">Welcome to My Portfolio</h2>
                    <p className="card-text" style={{ marginBottom: '0.5rem', fontSize: '1.2rem' }}>
                        *** Helping you explore my resume and portfolio ***
                    </p>
                    <p style={{ marginBottom: '0', fontSize: '1rem', fontStyle: 'italic' }}>
                        Please explore...
                    </p>
                </div>
            </div>

            {/* Resume Section Card */}
            <div className="card shadow-sm">
                <div className="card-body">
                    <h2 className="text-dark text-center mb-4">
                        Resume as of December 2024
                    </h2>

                    {/* Experience Section */}
                    <h4 className="text-primary">Experience</h4>
                    <ul>
                        {resumeData.experience.map((item, index) => (
                            <li key={index} style={{ marginBottom: '1rem' }}>
                                <strong>{item.title}</strong>
                                <ul style={{ marginTop: '0.5rem' }}>
                                    {item.details.map((detail, idx) => (
                                        <li key={idx}>{detail}</li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>

                    {/* Education Section */}
                    <h4 className="text-primary mt-4">Education</h4>
                    <ul>
                        {resumeData.education.map((item, index) => (
                            <li key={index}>
                                <strong>{item.year}:</strong> {item.degree}, {item.institution}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Home;
