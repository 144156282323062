const resumeData = {
    experience: [
      {
        title: "Current Role (Late 2019 - Present)",
        details: [
          "Full working skills with MS Office suite.",
          "Extensive experience with SQL, AWS, Tableau, Quicksight, Heap, and Full Story.",
          "Product, subscriptions, app, and web reporting expertise.",
          "Functional experience with Jira, Confluence, and Asana.",
          "Creation of cross-team insights and reporting tools (self-serve and automated reports).",
        ],
      },
      {
        title: "Field Technician, Operations Lead (2017 - 2019) - Amazon Inc.",
        details: [
          "Extensive knowledge of the smart home industry.",
          "Designed customer solutions tailored to unique needs.",
          "Proficient in troubleshooting, installations, and configurations.",
          "Educated customers on leveraging smart home features.",
          "Developed training materials and programs, collaborating with other Amazon teams.",
          "Quickly adapted to new skills and effectively implemented them in the field.",
        ],
      },
      {
        title:
          "Specialist / FRS / Genius / In-Store Guest Trainer (2011 - 2017) - Apple Inc.",
        details: [
          "Focused on delivering complete, personalized experiences for customers.",
          "Developed solutions by leveraging strong listening and technical learning skills.",
          "Set up customer devices and provided in-depth technical support.",
          "Facilitated training sessions for new and current team members.",
          "Resolved technical issues by evaluating, isolating, and troubleshooting.",
          "Served as a knowledgeable resource and shared expertise with the team.",
        ],
      },
      {
        title: "Food and Beverage Director (2006 - 2010) - Cataloochee Ski Resort",
        details: [
          "Interviewed, staffed, and scheduled team members effectively.",
          "Streamlined workflows to improve customer service efficiency.",
          "Built a cohesive team and delegated tasks for successful operations.",
        ],
      },
      {
        title:
          "Phone Operator, Pizza Maker, Store Manager (2005 - 2006) - Domino's Pizza",
        details: [
          "Managed interviewing, staffing, and scheduling processes.",
          "Onboarded and trained new employees.",
          "Generated reports and evaluated team performance metrics.",
        ],
      },
    ],
    education: [
      {
        year: "2003",
        degree: "High School Diploma",
        institution: "Indian Rocks Christian School, Largo, Florida",
      },
      {
        year: "2005",
        degree: "Associate's Degree in the Art of Science in Recording Arts",
        institution: "Full Sail Real World Education, Orlando, Florida",
      },
      {
        year: "2006",
        degree: "Bachelor's Degree in Business",
        institution: "Full Sail Real World Education, Orlando, Florida",
      },
    ],
  };
  
  export default resumeData;
  