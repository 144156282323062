import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import TechStack from './TechStack'; // Updated from SQL to TechStack
import Tableau from './Tableau';
import EduCerts from './EduCerts';

function App() {
    return (
        <Router>
            <div id="root">
                {/* Navigation Bar */}
                <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
                    <div className="container">
                        <Link className="navbar-brand fw-bold" to="/">Adam's Portfolio</Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <Link className="nav-link text-white fw-bold" to="/tech-stack">Tech Stack</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-white fw-bold" to="/tableau">Tableau</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link text-white fw-bold" to="/edu-certs">EDU / Certs</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

                {/* Main Content */}
                <main className="container my-5">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/tech-stack" element={<TechStack />} />
                        <Route path="/tableau" element={<Tableau />} />
                        <Route path="/edu-certs" element={<EduCerts />} />
                    </Routes>
                </main>

                {/* Footer */}
                <footer>
                    <p className="mb-0">© 2024 Adam's Portfolio. All rights reserved.</p>
                </footer>
            </div>
        </Router>
    );
}

export default App;
