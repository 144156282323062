function EduCerts() {
    return (
        <div>
            <h1>Education and Certifications</h1>
            <p>Highlight certifications and educational achievements here.</p>
        </div>
    );
}

export default EduCerts;
