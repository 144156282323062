import React, { useState } from 'react';
import imageData from './imageData'; // Import the image data for both the grid and carousel

function TechStack() {
    const [carouselContent, setCarouselContent] = useState(imageData[0]); // Default to the first image

    // Update carousel content based on grid icon clicked
    const updateCarouselContent = (item) => {
        setCarouselContent(item);
    };

    return (
        <div className="container mt-4">
            {/* Page Title */}
            <div className="text-center mb-4">
                <h2 className="text-primary">Tech Stack</h2>
            </div>

            {/* Grid Section for Icons */}
            <div className="row text-center mb-4">
                {imageData.map((item, index) => (
                    <div
                        key={index}
                        className="col-6 col-md-3 mb-3"
                        onClick={() => updateCarouselContent(item)}
                        style={{ cursor: 'pointer' }}
                    >
                        <img
                            src={item.src}
                            alt={item.caption}
                            className="img-fluid shadow-sm rounded grid-icon"
                        />
                        <p className="mt-2 fw-bold">{item.caption}</p>
                    </div>
                ))}
            </div>

            {/* Carousel Section */}
            <div className="card shadow-sm">
                <div className="card-body">
                    <div className="d-flex justify-content-center">
                        <div id="carouselSection" className="carousel slide">
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <img
                                        src={carouselContent.src}
                                        alt={carouselContent.caption}
                                        className="d-block mx-auto rounded shadow"
                                        style={{ maxHeight: '250px', maxWidth: '300px', width: 'auto' }}
                                    />
                                    <div className="text-center mt-3">
                                        <h5 className="text-primary">{carouselContent.caption}</h5>
                                        <div className="text-start">
                                            {carouselContent.description.map((line, idx) => (
                                                <p
                                                    key={idx}
                                                    dangerouslySetInnerHTML={{ __html: line }}
                                                    style={{
                                                        margin: line.includes('Current Use Cases:')
                                                            ? '0'
                                                            : '0 0 0.5rem 0',
                                                        textAlign: line.includes('Current Use Cases:')
                                                            ? 'center'
                                                            : 'inherit',
                                                        fontWeight: idx === 0 ? 'bold' : 'normal',
                                                    }}
                                                ></p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Custom Navigation Below the Image */}
                    <div className="d-flex justify-content-center mt-3">
                        <button
                            className="btn btn-primary me-2"
                            type="button"
                            data-bs-target="#carouselSection"
                            data-bs-slide="prev"
                        >
                            Previous
                        </button>
                        <button
                            className="btn btn-primary"
                            type="button"
                            data-bs-target="#carouselSection"
                            data-bs-slide="next"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TechStack;
