const imageData = [
    {
        src: require('./images/Excelicon.png'),
        caption: 'Excel',
        description: [
            '<strong>- 10+ years of experience</strong> and <strong>Certified</strong>',
            '<strong>Current Use Cases:</strong>',
            '<strong>--</strong> Specific data sharing, and Debugging',
            '<strong>--</strong> CSV files to create DB tables (great for Stakeholder participation)',
            '<strong>--</strong> Adhoc Requests / Sharing Adhoc Results'
        ]
    },
    {
        src: require('./images/SQLicon.png'),
        caption: 'SQL',
        description: [
            '<strong>- 6+ years</strong> of experience.',
            '<strong>Current Use Cases:</strong>',
            '<strong>--</strong> Querying DBs (structured and non-structured)',
            '<strong>--</strong> JOINs / Sub-Queries / Create Tables / Manage DB Tables',
            '<strong>--</strong> Extensive knowledge of SQL functions and practical use cases',
            '<strong>--</strong> Please see the SQL section for Examples',
        ]
    },
    {
        src: require('./images/Tableauicon.png'),
        caption: 'Tableau',
        description: [
            '<strong>- 5+ years of experience</strong> and <strong>Certified</strong>',
            '<strong>Current Use Cases:</strong>',
            '<strong>--</strong> Create, Maintain, and Improve Reporting Dashboards / Tools',
            '<strong>--</strong> Work with Stakeholders to create views/dashboards, designed to save time and be AI friendly',
            '<strong>--</strong> Create universal design components, create familiarity across dashboards',
            '<strong>--</strong> Please see the Tableau section for Examples',
        ]
    },
    {
        src: require('./images/AWSicon.png'),
        caption: 'AWS',
        description: [
            '<strong>- 6+ years of experience</strong> and <strong>Certified</strong>',
            '<strong>Current Use Cases:</strong>',
            '<strong>--</strong> Account configuration, IAM, Services, DB Configuration and Management',
            '<strong>--</strong> Managing Costs and Resources',
            '<strong>--</strong> Configuring New Cloud Services, and maintain the Security Posture',
            '<strong>--</strong> Expand and Scale as needed, Safely and Efficiently',
        ]
    }
];

export default imageData;
